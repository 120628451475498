<header>
	<div class="phd-navigation-bar-header">
		<img class="phd-logo" src="assets/PG_Logo_2022_Horizontal_Y&W.png" alt="Pulte Homes" />
	</div>
	<aside>
		<div class="user" *ngIf="user"><i class="fas fa-user"></i>{{user.displayName}}</div>
	</aside>
</header>

<router-outlet></router-outlet>

<build-version *ngIf="!environment.production" [branch]="branch" [version]="version"></build-version>
