import { Injectable, Inject, forwardRef } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, Router, UrlTree } from '@angular/router';

import { Observable, of } from 'rxjs';

import { IdentityService } from '../services/identity.service';
import { ClaimTypes } from '../models/claims.model';

@Injectable()
export class ClaimGuard implements CanActivate 
{
	entryPoints = ['/', '/contracts', 'community-management'];

	constructor(@Inject(forwardRef(() => IdentityService)) private identityService: IdentityService, private router: Router) { }

	canActivate(next: ActivatedRouteSnapshot): Observable<boolean> | UrlTree
	{
		const claim = next.data['requiresClaim'] as ClaimTypes;

		if (this.identityService.hasClaim(claim)) 
		{
			return of(true);
		}
		else
		{		
			const url = this.entryPoints.pop();

			if (!!url)
			{
				return this.router.parseUrl(url);
			}
			return of(false);
		}
	}
}
