/**
 * Flip keys and values for given object
 * @param obj
 */
function objectFlip(obj) {
  const result = {};
  Object.keys(obj).forEach(key => {
    result[obj[key]] = key;
  });
  return result;
}
export { objectFlip };