import { QualifierValue } from "../../../../internal/qualifier/QualifierValue.js";
/**
 * @memberOf Qualifiers.FocusOn
 * @extends {SDK.QualifierValue}
 */
class FocusOnValue extends QualifierValue {
  constructor(name) {
    super();
    this.name = name;
  }
  toString() {
    return this.name;
  }
}
export { FocusOnValue };