<div class="phd-salestally">
	<div class="row align-items-center justify-content-center">
		<h2>Sales Tally</h2>
	</div>

	<div class="row align-items-center justify-content-center phd-stlly-options">
		
			<span class="phd-stlly-time">
				<input type="radio" id="currentweek" (click)="updateTop10Data(TimeFrameType.CurrentWeek)" [checked]="timeFrame == TimeFrameType.CurrentWeek" />
				<label class="phd-stlly-time-label">Current Week</label>
			</span>

			<span class="phd-stlly-time">
				<input type="radio" id="mtd" (click)="updateTop10Data(TimeFrameType.MonthToDate)" [checked]="timeFrame == TimeFrameType.MonthToDate" />
				<label class="phd-stlly-time-label">Month To Date</label>
			</span>

			<span>
				<input type="radio" id="ytd" (click)="updateTop10Data(TimeFrameType.YearToDate)" [checked]="timeFrame == TimeFrameType.YearToDate" />
				<label class="phd-stlly-time-label">Year To Date</label>
			</span>
	</div>
	<hr />

	<div class="align-items-top row">

		<div class="col">
			<table class="table table-bordered">
				<tr class="phd-salestally-top10-hdr" (click)="onToggleTable('market')">
					<th>Top 10 Markets</th>
					<th>Signups</th>
				</tr>
				<tr *ngFor="let mkt of topMarkets; let odd = odd" [ngClass]="odd ? 'phd-stlly-top10-row-odd' : 'phd-stlly-top10-row-even'" [class.phd-hide-table]="!showTopMarkets">
					<td>{{mkt.market}}</td>
					<td>{{mkt.net}}</td>
				</tr>
			</table>
		</div>

		<div class="col">
			<table class="table table-bordered">
				<tr class="phd-salestally-top10-hdr" (click)="onToggleTable('community')">
					<th>Top 10 Communities</th>
					<th>Signups</th>
				</tr>
				<tr *ngFor="let cmts of topCommunities; let odd = odd" [ngClass]="odd ? 'phd-stlly-top10-row-odd' : 'phd-stlly-top10-row-even'" [class.phd-hide-table]="!showTopCommunities">
					<td>{{cmts.community}}</td>
					<td>{{cmts.net}}</td>
				</tr>
			</table>
		</div>

		<div class="col">
			<table class="table table-bordered">
				<tr class="phd-salestally-top10-hdr" (click)="onToggleTable('agent')">
					<th>Top 10 Sales Agents</th>
					<th>Signups</th>
				</tr>
				<tr *ngFor="let sa of topSalesConsultants; let odd = odd" [ngClass]="odd ? 'phd-stlly-top10-row-odd' : 'phd-stlly-top10-row-even'" [class.phd-hide-table]="!showTopSalesConsultants">
					<td>{{sa.salesAssociate}}</td>
					<td>{{sa.totalSales}}</td>
				</tr>
			</table>
		</div>

	</div>

	<button class="btn btn-link" (click)="collapseAll()">Collapse All</button>

	<ng-container *ngIf="isPortrait">
		<p-treeTable #salesTree [value]="areaSales" [columns]="scrollableCols" [frozenColumns]="frozenCols"
			[scrollable]="true" scrollHeight="400px" [frozenWidth]="frozenWidth">
			<ng-template pTemplate="colgroup" let-columns>
				<colgroup>
					<col *ngFor="let col of columns" style="width:58px">
				</colgroup>
			</ng-template>			
			<ng-template pTemplate="header" let-columns>
				<tr>
					<ng-container *ngFor="let col of columns">
						<th *ngIf="col.displayHeader" [attr.colspan]="col.headerColspan">
							<div [ngClass]="col.headerClass">{{col.firstHeader}}</div>
						</th>
					</ng-container>
				</tr>
				<tr>
					<th *ngFor="let col of columns">
						<div class="phd-stlly-second-header">{{col.secondHeader}}</div>
					</th>
				</tr>
			</ng-template>
			<ng-template pTemplate="body" let-rowNode let-rowData="rowData">
				<tr>
					<ng-container *ngFor="let col of scrollableCols">
						<td>
							{{rowData[col.field]  | number:'1.0-2'}}
						</td>
					</ng-container>
				</tr>
			</ng-template>
			<ng-template pTemplate="frozenheader">
				<tr>
					<th></th>
				</tr>
				<tr>
					<th></th>
				</tr>				
			</ng-template>
			<ng-template pTemplate="frozenbody" let-rowNode let-rowData="rowData">
				<tr>
					<td>
						<div class="d-flex flex-row">
							<p-treeTableToggler [rowNode]="rowNode"></p-treeTableToggler>
							<span>{{rowData.name}}</span>
						</div>
					</td>
				</tr>            
			</ng-template>			
		</p-treeTable>
	</ng-container>

	<ng-container *ngIf="!isPortrait">
		<p-treeTable #salesTree [value]="areaSales" [columns]="cols" [scrollable]="true" scrollHeight="400px" (onNodeExpand)="onNodeExpand($event)">
			<ng-template pTemplate="header" let-columns>
				<tr class="phd-stlly-area-hdr-gold">
					<ng-container *ngFor="let col of columns">
						<th *ngIf="col.displayHeader && displayInMobile(col)" [attr.colspan]="col.headerColspan">
							<div [ngClass]="col.headerClass">{{col.firstHeader}}</div>
						</th>
					</ng-container>
				</tr>
				<tr class="phd-stlly-area-hdr-blue">
					<ng-container *ngFor="let col of columns">
						<th *ngIf="displayInMobile(col)" [attr.colspan]="col.bodyColspan">
							<div class="phd-stlly-second-header">{{col.secondHeader}}</div>
						</th>						
					</ng-container>

				</tr>
			</ng-template>
			<ng-template pTemplate="body" let-rowNode let-rowData="rowData">
				<tr>
					<ng-container *ngFor="let col of cols; let i = index">
						<td *ngIf="displayInMobile(col)" [attr.colspan]="col.bodyColspan">
							<div *ngIf="i === 0" class="d-flex flex-row">
								<p-treeTableToggler [rowNode]="rowNode"></p-treeTableToggler>
								<span>{{rowData[col.field]}}</span>
							</div>
							<div *ngIf="i !== 0">{{rowData[col.field]  | number:'1.0-2'}}</div>
						</td>
					</ng-container>
				</tr>
			</ng-template>
		</p-treeTable>		
	</ng-container>
	
</div>
