<portal-item title="Sales Admin" icon="calendar-check" [action]="salesAdminAction" *ngIf="canAccessSalesAdmin"></portal-item>

<portal-item title="Dynamics 365" icon="users" [action]="d365Action"></portal-item>

<portal-item title="Buyer Tracker" icon="tasks" [action]="buyerTrackerAction"></portal-item>

<portal-item title="Create Specs and Models" icon="home" *requiresClaim="'SpecOrModel'; permission: Permission.Create" #spec>
	<ng-template>
		<spec-homes [action]="specAction" (onClose)="spec.close()"></spec-homes>
	</ng-template>
</portal-item>

<portal-item title="Choice Admin" icon="wrench" [action]="choiceAdminAction" *requiresClaim="'NationalCatalog'; permission: Permission.Read"></portal-item>

<portal-item title="Reporting" icon="chart-pie" *ngIf="canAccessReports" [action]="reportsAction" #reporting></portal-item>

<portal-item title="Search" icon="search" *requiresClaim="'SalesAgreements'; permission: Permission.Read" modalSize="lg" #search>
	<ng-template>
		<phd-search [action]="searchAction" (onClose)="search.close()"></phd-search>
	</ng-template>
</portal-item>

<portal-item title="Preview" icon="binoculars" *requiresClaim="'SalesAgreements'; permission: Permission.Read" #preview>
	<ng-template>
		<plan-preview [action]="previewAction" [roles]="roles" (onClose)="preview.close()"></plan-preview>
	</ng-template>
</portal-item>
