/**
 * Sort a map by key
 * @private
 * @param map <string, any>
 * @Return array of map's values sorted by key
 */
function mapToSortedArray(map, flags) {
  const array = Array.from(map.entries());
  // objects from the Array.from() method above are stored in array of arrays:
  // [[qualifierKey, QualifierObj], [qualifierKey, QualifierObj]]
  // Flags is an array of FlagQualifierObj
  // We need to convert it to the same form: [flagQualifier] =>  ['fl', flagQualifier]
  flags.forEach(flag => {
    array.push(['fl', flag]); // push ['fl', flagQualifier]
  });
  return array.sort().map(v => v[1]);
}
/**
 * Checks if `value` is a string.
 * @private
 * @param {*} value The value to check.
 * @return {boolean} `true` if `value` is a string, else `false`.
 */
function isString(value) {
  return typeof value === 'string' || value instanceof String;
}
export { isString, mapToSortedArray };