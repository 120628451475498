import { Injectable, Inject, forwardRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, ReplaySubject, throwError as _throw, from, of, throwError } from 'rxjs';

import { OAuthService, AuthConfig, TokenResponse } from 'angular-oauth2-oidc';

import { map, catchError, tap, share, take, switchMap, filter } from 'rxjs/operators';

import { ApplicationInsights } from '@microsoft/applicationinsights-web';

import { ClaimTypes, Claims, Permission } from '../models/claims.model';
import { API_URL, AUTH_CONFIG, WINDOW_ORIGIN } from '../injection-tokens';
import { UserProfile } from '../models/user-profile.model';

function isObservable<T>(obj: Observable<T> | T): obj is Observable<T>
{
	return obj instanceof Observable;
}

@Injectable()
export class IdentityService
{
	//private _user: ReplaySubject<adal.User> = new ReplaySubject(1);
	private loggedInSubject$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);
	private reloginSubject$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

	private readonly _graphUrl = 'https://graph.microsoft.com';

	private claims: Claims;
	private assignedMarkets: Array<{ id: number, number: string }>;
	private getClaims$: Observable<Claims>;
	private getAssignedMarkets$: Observable<Array<{ id: number, number: string }>>;

	enableAutoRelogin: boolean = false;

	public set relogin(value: boolean)
	{
		this.reloginSubject$.next(value);
	}

	public get token(): Observable<string>
	{
		return this.loggedInSubject$.pipe(
			take(1),
			map(() => this.osvc.getAccessToken())
		);
	}

	public get user(): Observable<UserProfile>
	{
		return this.loggedInSubject$.pipe(
			take(1),
			map(loggedIn =>
			{
				if (loggedIn)
				{
					const claims: any = this.osvc.getIdentityClaims() ?? {};

					return {
						displayName: claims.name || null,
						familyName: claims.family_name || null,
						givenName: claims.given_name || null,
						businessPhones: null,
						city: null,
						mail: null,
						mobilePhone: null,
						postalCode: null,
						state: null,
						streetAddress: null,
						upn: claims.preferred_username
					} as UserProfile;
				}
				else
				{
					return null;
				}
			})
		);
	}

	public get isLoggedIn(): Observable<boolean>
	{
		return this.loggedInSubject$;
	}

	public get tokenEndpoint(): string
	{
		return this.osvc.tokenEndpoint;
	}

	constructor(@Inject(forwardRef(() => HttpClient)) private httpClient: HttpClient,
		@Inject(forwardRef(() => OAuthService)) private osvc: OAuthService,
		@Inject(forwardRef(() => API_URL)) private apiUrl: string,
		@Inject(forwardRef(() => AUTH_CONFIG)) private authConfig: AuthConfig | Observable<AuthConfig>,
		@Inject(forwardRef(() => WINDOW_ORIGIN)) private origin: string,
		@Inject(forwardRef(() => ApplicationInsights)) private appInsights: ApplicationInsights)
	{
		if (isObservable(this.authConfig))
		{
			this.authConfig.pipe(take(1)).subscribe(config =>
			{
				this.configure(config);
			});
		}
		else
		{
			this.configure(this.authConfig);
		}

		this.getClaims$ = this.httpClient.get<Claims>(`${this.apiUrl}GetUserPermissions`).pipe(
			tap(c => this.claims = c),
			share()
		);

		this.getAssignedMarkets$ = this.httpClient.get<any>(`${this.apiUrl}assignedMarkets?$select=id,number&$filter=companyType eq 'HB' and salesStatusDescription eq 'Active'`).pipe(
			map(response => response.value.map(mkt => <{ id: number, number: string }>mkt)),
			tap(mkts => this.assignedMarkets = mkts),
			share()
		);

		this.loggedInSubject$.pipe(
			filter(loggedIn => loggedIn),
			take(1)
		).subscribe(() => 
		{
			this.appInsights.setAuthenticatedUserContext(this.osvc.getIdentityClaims()['preferred_username']);
		});
	}

	private configure(authConfig: AuthConfig)
	{
		// Set the redirect URI in the authConfig object to the origin of the current window
		authConfig.redirectUri = this.origin;

		//if it's B2C and not localhost, then set the redirectUri to the designpreview page
		if (authConfig.issuer?.indexOf('https://auth.') > -1 && this.origin.indexOf('localhost') === -1)
		{
			// update issuer to point to correct brand url
			var brandDomain = this.origin.match(/https?:\/\/[^/]*\.([^.]+)\.com(?:\/.*)?/);
			var brand = brandDomain && brandDomain[1] ? brandDomain[1].toLowerCase() : '';
			if (brand !== 'pulte' && authConfig.issuer)
			{
				authConfig.issuer = authConfig.issuer.replace('.pulte.com/', '.' + brandDomain[1] + '.com/');
			}

			authConfig.redirectUri = this.origin + '/designpreview/';
		}

		// Configure the OAuthService with the provided authConfig object
		this.osvc.configure(authConfig);
		// Set up the OAuthService to automatically refresh the access token when it gets close to expiring
		this.osvc.setupAutomaticSilentRefresh();

		// If the application is running on 'http://localhost:2845'
		if (this.apiUrl.indexOf('http://localhost:2845') === 0)
		{
			// If the current URL does not contain a 'code' query parameter and is not the root URL
			if (window.location.search.indexOf('?code=') !== 0 && window.location.pathname !== '/')
			{
				// Save the current URL in the session storage
				this.setCurrentURIState();
			}
		}

		// Load the OAuth2/OpenID Connect discovery document and try to log in the user
		this.osvc.loadDiscoveryDocumentAndTryLogin().then(res =>
		{
			// If the user is logged in, set the loggedInSubject$ to true
			this.loggedInSubject$.next(this.osvc.hasValidAccessToken() && this.osvc.hasValidIdToken());

			// If the user is on the root URL and there is a saved URL in the session storage
			if (window.location.pathname === '/' && sessionStorage.getItem('uri_state') && this.osvc.hasValidIdToken())
			{
				// Get the saved URL from the session storage
				const uri = sessionStorage.getItem('uri_state');

				// Remove the saved URL from the session storage
				sessionStorage.removeItem('uri_state');

				sessionStorage.setItem('try_relogin', 'true');

				// Redirect the user to the saved URL
				window.location.href = uri;
			}

			// Set the logout URL in the OAuthService to the logout URL from the authConfig object
			this.osvc.logoutUrl = authConfig.logoutUrl;
		});

		// If the current URL contains an 'iss' query parameter
		if (window.location.search.match(/iss=[^&$]*/)) 
		{
			// Remove the 'iss' query parameter from the URL
			const href = location.origin + location.pathname + location.search.replace(/iss=[^&$]*/, '');

			location.hash;

			// Replace the current URL with the new URL
			history.replaceState(null, window.name, href);
		}
	}

	public init(): Observable<boolean>
	{
		return this.loggedInSubject$.pipe(take(1));
	}

	initAutoRelogin(): Observable<boolean>
	{
		// Enable the automatic re-login feature
		this.enableAutoRelogin = true;

		// Return an Observable that emits the latest re-login status and then completes
		return this.reloginSubject$.pipe(take(1));
	}

	public login(state?: any): void
	{
		this.osvc.initLoginFlow(state ? JSON.stringify(state) : null);
	}

	public tryReloginUser(): void
	{
		// Save the current URL in the session storage
		this.setCurrentURIState();

		// Set uri_state to the current page
		this.setCookie('uri_state', window.location.href, 5);

		// Delete the uri_query_params cookie to stop the page from trying to load uri_state + uri_query_params which will error.
		this.deleteCookie('uri_query_params');

		this.login();
	}

	public logout(): void
	{
		this.osvc.logOut();
	}

	public refreshUser(): Observable<TokenResponse>
	{
		return this.isLoggedIn.pipe(
			switchMap(loggedIn =>
			{
				// If the user is logged in then lets check to make sure his refresh token is still valid
				return loggedIn ? this.refreshToken() : throwError('User is not logged in');
			}));
	}

	public refreshToken(): Observable<TokenResponse>
	{
		return from(this.osvc.refreshToken());
	}

	public setCurrentURIState()
	{
		sessionStorage.setItem('uri_state', window.location.href);
	}

	// gets profile info from AD via Graph
	public getMe(): Observable<UserProfile>
	{
		return of(null);
		//return this.service.userInfo && this.service.userInfo.authenticated ? of(this.service.userInfo.token) : this.getAccessToken()
		//	.pipe(
		//		flatMap(accessToken =>
		//		{
		//			let headers = new HttpHeaders();
		//			headers = headers.append('Authorization', 'Bearer ' + accessToken);

		//			const url = 'https://graph.microsoft.com/v1.0/me/?$select=displayName,mail,streetAddress,city,postalCode,state,mobilePhone,businessPhones';
		//			return this.httpClient.get(url, { headers })
		//				.pipe(
		//					map(response =>
		//					{
		//						const profile = response as UserProfile;
		//						return profile;
		//					}));
		//		})
		//		, catchError(this.handleError)
		//	);
	}

	// gets the AD roles that the user belongs to
	public getRoles(): Observable<string[]>
	{
		return this.loggedInSubject$.pipe(
			take(1),
			map(loggedIn =>
			{
				if (loggedIn)
				{
					const claims: any = this.osvc.getIdentityClaims() ?? {};

					return claims.roles || [];
				}
				else
				{
					return null;
				}
			})
		);
	}

	// checks to see if user belongs to all roles
	public isInAllRoles(...neededRoles: Array<string>): Observable<boolean>
	{
		return this.getRoles()
			.pipe(
				map(roles =>
				{
					roles = roles.map(role => role.toLocaleLowerCase());

					return neededRoles.every(neededRole => roles.includes(neededRole.toLocaleLowerCase()));
				})
				, catchError(this.handleError)
			);
	}

	// checks to see if user belongs to a specific role
	public isInRole(neededRole: string): Observable<boolean>
	{
		neededRole = neededRole.toLocaleLowerCase();

		return this.getRoles()
			.pipe(
				map(roles =>
				{
					roles = roles.map(role => role.toLocaleLowerCase());

					return roles.includes(neededRole);
				})
				, catchError(this.handleError)
			);
	}

	// checks to see if user has any of the roles specified
	public hasRole(neededRoles: Array<string>): Observable<boolean>
	{
		return this.getRoles()
			.pipe(
				map(roles =>
				{
					roles = roles.map(role => role.toLocaleLowerCase());

					return neededRoles.some(neededRole => roles.includes(neededRole.toLocaleLowerCase()));
				})
				, catchError(this.handleError)
			);
	}

	// gets access token for Graph API
	private getAccessToken(): Observable<string>
	{
		return of(null);
		//const token = this.service.getCachedToken(this._graphUrl);

		//if (token)
		//{
		//	return of(token);
		//}

		//return this.service.acquireToken(this._graphUrl)
		//	.pipe(
		//		flatMap(token =>
		//		{
		//			return token;
		//		}));
	}

	private handleError(error: any)
	{
		// In the future, we may send the server to some remote logging infrastructure
		console.error(error);

		return _throw(error || 'Server error');
	}

	public hasClaim(claim: ClaimTypes): Observable<boolean>
	{
		return this.getClaims().pipe(
			map(c => !!c[claim])
		);
	}

	public hasClaimWithPermission(claim: ClaimTypes, permission: Permission): Observable<boolean>
	{
		return this.getClaims().pipe(
			map(c => c[claim] || 0),
			map(p => (permission & p) !== 0)
		);
	}

	public hasMarket(market: number | string): Observable<boolean>
	{
		return this.getAssignedMarkets().pipe(
			map(mkts =>
			{
				if (typeof market === 'number')
				{
					return mkts.some(mkt => mkt.id === market);
				}
				else
				{
					return mkts.some(mkt => mkt.number === market);
				}
			})
		);
	}

	public getClaims(): Observable<Claims>
	{
		if (this.claims)
		{
			return of(this.claims);
		}

		return this.getClaims$;
	}

	public getAssignedMarkets(): Observable<Array<{ id: number, number: string }>>
	{
		if (this.assignedMarkets)
		{
			return of(this.assignedMarkets);
		}

		return this.getAssignedMarkets$;
	}

	public getContactId(): Observable<number>
	{
		return this.loggedInSubject$.pipe(
			take(1),
			map(loggedIn =>
			{
				if (loggedIn)
				{
					const claims: any = this.osvc.getIdentityClaims() ?? {};

					return claims.oid || null;
				}
				else
				{
					return null;
				}
			}),
			switchMap(oid => !!oid
				? this.httpClient.get<any>(`${this.apiUrl}contacts?$filter=adIntegrationKey eq ${oid}&$select=id`)
				: of(null)),
			map(response => response && response.value && response.value.length ? response.value[0].id : 0)
		);
	}

	private setCookie(name: string, value: string, minutes: number)
	{
		const d = new Date();

		d.setTime(d.getTime() + (minutes * 60 * 1000)); // Convert minutes to milliseconds

		const expires = 'expires=' + d.toUTCString();

		document.cookie = name + '=' + value + ';' + expires + ';path=/';
	}

	private deleteCookie(name)
	{
		document.cookie = name + '=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
	}
}

export const Roles = {
	ItSupport: 'ITSupport',
	CatalogManager: 'CatalogManager',
	TreeManager: 'TreeManager',
	AllOrgs: 'ORG_All',
	HomeSiteManager: 'SiteAdmin',
	SalesConsultant: 'SalesConsultant',
	SalesManager: 'SalesManager',
	/** Gets the dynamic role name for a specific org */
	forOrg(orgId: string)
	{
		return `ORG_${orgId}`;
	}
};
