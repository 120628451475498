<div class="modal-header">
	<span class="modal-title">Create Spec or Model</span>

	<button type="button" class="close" aria-label="Close" (click)="close()">
		<span aria-hidden="true">&times;</span>
	</button>
</div>

<div class="modal-body">
	<sales-community-selector
							  (onMarketChange)="onMarketChange($event)"
							  (onSalesCommunityChange)="onSalesCommunityChange($event)"
							  (onFinancialCommunityChange)="onFinancialCommunityChange($event)"
							  [showLabels]="true">
	</sales-community-selector>

	<div class="phd-build-type-group">
		<button class="btn" (click)="changeBuildType('spec')" [ngClass]="selectedSpecOrHomes === 'spec' ? 'btn-primary' : 'btn-secondary'">Spec</button>

		<button class="btn" (click)="changeBuildType('model')" [ngClass]="selectedSpecOrHomes === 'model' ? 'btn-primary' : 'btn-secondary'">Model</button>
	</div>
</div>

<div class="modal-footer">
	<button class="btn btn-primary phd-btn-save" [disabled]="!!!selectedSalesCommunity" (click)="save();">Continue</button>

	<button class="btn btn-secondary" (click)="close();">Cancel</button>
</div>

