import { QualifierValue } from "../../../../internal/qualifier/QualifierValue.js";
/**
 * @memberOf Qualifiers.Compass
 * @extends {SDK.QualifierValue}
 */
class CompassQualifier extends QualifierValue {
  constructor(val) {
    super();
    this.val = val;
  }
  toString() {
    return this.val;
  }
}
export { CompassQualifier };