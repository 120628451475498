import { createUnsupportedError } from "../utils/unsupportedError.js";
/**
 * Returns the action's model
 */
export function actionToJson() {
  var _a, _b, _c;
  const actionModelIsNotEmpty = this._actionModel && Object.keys(this._actionModel).length;
  const sourceTransformationError = (_c = (_b = (_a = this._actionModel) === null || _a === void 0 ? void 0 : _a.source) === null || _b === void 0 ? void 0 : _b.transformation) === null || _c === void 0 ? void 0 : _c.error;
  // Should return error when there is unsupported transformation inside
  if (sourceTransformationError && sourceTransformationError instanceof Error) {
    return {
      error: sourceTransformationError
    };
  }
  if (actionModelIsNotEmpty) {
    return this._actionModel;
  }
  return {
    error: createUnsupportedError(`unsupported action ${this.constructor.name}`)
  };
}