import { Directive, ElementRef, EventEmitter, HostListener, Input, NgZone, OnDestroy, OnInit, Output } from '@angular/core';
import { PhdTableComponent } from './phd-table.component';

@Directive({
	selector: '[phdRowDrag]'
})
export class RowDragDirective implements OnInit, OnDestroy
{
	@Input('phdRowDrag') index: number;
	@Input() dragEnabled: boolean = true;
	@Input() enableScroll = true;
	@Input() scrollElement: string = 'body';

	@Output('onRowDragStart') dragStart = new EventEmitter<[number, ElementRef, any[]]>();
	@Output('onRowDragEnter') dragEnter = new EventEmitter<[number, ElementRef, any[]]>();
	@Output('onRowDragOver') dragOver = new EventEmitter<[number, ElementRef, any[]]>();
	@Output('onRowDragLeave') dragLeave = new EventEmitter<[number, ElementRef, any[]]>();

	constructor(private el: ElementRef, public dt: PhdTableComponent, private ngZone: NgZone) { }

	ngOnInit()
	{
		this.bindEvents();
	}

	bindEvents()
	{
		this.ngZone.runOutsideAngular(() =>
		{
			this.el.nativeElement.addEventListener('dragstart', this.onDragStart.bind(this));
			this.el.nativeElement.addEventListener('dragenter', this.onDragEnter.bind(this));
			this.el.nativeElement.addEventListener('dragover', this.onDragOver.bind(this));
			this.el.nativeElement.addEventListener('dragleave', this.onDragLeave.bind(this));
			this.el.nativeElement.addEventListener('dragend', this.onDragEnd.bind(this));
		});
	}

	ngOnDestroy()
	{
		this.ngZone.runOutsideAngular(() =>
		{
			this.el.nativeElement.removeEventListener('dragstart', this.onDragStart.bind(this));
			this.el.nativeElement.removeEventListener('dragenter', this.onDragEnter.bind(this));
			this.el.nativeElement.removeEventListener('dragover', this.onDragOver.bind(this));
			this.el.nativeElement.removeEventListener('dragleave', this.onDragLeave.bind(this));
			this.el.nativeElement.removeEventListener('dragend', this.onDragEnd.bind(this));
		});
	}

	private onDragStart(event: any): void
	{
		if (!this.dragEnabled)
		{
			return;
		}

		if (this.enableScroll)
		{
			this.ngZone.runOutsideAngular(() =>
			{
				setTimeout(() =>
				{
					var scrollZone = document.querySelector(this.scrollElement);
					var topScrollZone = document.createElement('div');

					topScrollZone.id = 'scroll-top';
					topScrollZone.innerHTML = '<i class="fa fa-chevron-up fa-2x"></i>';
					topScrollZone.addEventListener('dragover', (evt) =>
					{
						scrollZone.scrollBy(0, -10);
					});

					scrollZone.appendChild(topScrollZone);

					var bottomScrollZone = document.createElement('div');

					bottomScrollZone.id = 'scroll-bottom';
					bottomScrollZone.innerHTML = '<i class="fa fa-chevron-down fa-2x"></i>';

					bottomScrollZone.addEventListener('dragover', (evt) =>
					{
						scrollZone.scrollBy(0, 10);
					});

					scrollZone.appendChild(bottomScrollZone);
				}, 100);
			});
		}

		this.dragStart.emit([this.index, this.el, event]);
	}

	private onDragEnter(event: any): void
	{
		this.dragEnter.emit([this.index, this.el, event]);
	}

	private onDragOver(event: any): void
	{
		this.dragOver.emit([this.index, this.el, event]);
	}

	private onDragLeave(event: any): void
	{
		this.dragLeave.emit([this.index, this.el, event]);
	}

	@HostListener('dragend') onDragEnd(): void
	{
		var scrollZone = document.querySelector(this.scrollElement);
		var scrollTop = document.querySelector('#scroll-top');
		var scrollBottom = document.querySelector('#scroll-bottom');

		if (scrollTop)
		{
			scrollZone.removeChild(scrollTop);
		}

		if (scrollBottom)
		{
			scrollZone.removeChild(scrollBottom);
		}
	}
}
