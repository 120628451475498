import { Qualifier } from "../../../../internal/qualifier/Qualifier.js";
/**
 * @description Defines the visual appearance of the background.
 * @memberOf Qualifiers.Background
 * @extends {SDK.Qualifier}
 */
class BackgroundQualifier extends Qualifier {
  constructor(backgroundValue) {
    // The qualifier key for this qualifier
    super('b');
    // Such as color (b_red)
    if (backgroundValue) {
      this.addValue(backgroundValue);
    }
  }
}
export { BackgroundQualifier };